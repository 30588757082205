<template>
    <div class="form-connection" v-if="translates && translates[langUrl]">
        <div class="form-connection__title">{{ title }}</div>
        <div v-if="!formSended">
            <ValidationObserver ref="validator" slim>
                <ValidationProvider v-slot="{ errors }" rules="required">
                    <BaseTextField
                        v-model="feedback.name"
                        class="form"
                        :placeholder="translates[langUrl].placeholder_firstname[lang]"
                        :error-messages="errors[0]"
                    />
                </ValidationProvider>
                <ValidationProvider v-slot="{ errors }" rules="required">
                    <BasePhoneNumberInput
                        v-model="feedback.phone"
                        @update="number($event)"
                        :error-messages="errors[0]"
                    />
                </ValidationProvider>
                <ValidationProvider v-slot="{ errors }" rules="required">
                    <BaseTextField
                        v-model="feedback.email"
                        class="form"
                        :placeholder="translates[langUrl].placeholder_email[lang]"
                        :error-messages="errors[0]"
                    />
                </ValidationProvider>
                <ValidationProvider v-slot="{ errors }" rules="required">
                    <BaseTextField
                        v-model="feedback.companyName"
                        class="form"
                        :placeholder="translates[langUrl].placeholder_companyName[lang]"
                        :error-messages="errors[0]"
                    />
                </ValidationProvider>
                <BaseTextField
                    v-model="feedback.site"
                    class="form"
                    :placeholder="translates[langUrl].placeholder_site[lang]"
                    @keyup.enter="send"
                />
                <BaseTextField
                    v-model="feedback.region"
                    class="form"
                    :placeholder="translates[langUrl].placeholder_region[lang]"
                    @keyup.enter="send"
                />
            </ValidationObserver>
            <BaseButton
                class="button-fill"
                style="width:100%; margin-top: 12px;"
                @click="send">{{ translates[langUrl].button_send[lang] }}</BaseButton>
        </div>
        <div v-if="formSended" class="form-connection__sended">
            <div class="form-connection__sended__text" v-html="translates[langUrl].sended_text[lang]"></div>
            <BaseButton
                class="button-fill"
                style="width:100%"
                @click="formReverse"
            >{{ translates[langUrl].formReverse[lang] }}</BaseButton>
        </div>
    </div>
</template>
<script>
    import store from '@/store';
    import { mapState } from 'vuex';

    import BaseTextField from './BaseTextField.vue'
    import BaseTextArea from './BaseTextArea.vue'
    import BaseButton from './BaseButton.vue'
    import BaseCheckbox from './BaseCheckbox.vue'
    import { ValidationProvider, ValidationObserver } from 'vee-validate';
    import BasePhoneNumberInput from './BasePhoneInput.vue';

    export default {
        name: 'FormConnection',
        components: {
            BaseTextField,
            BaseTextArea,
            BaseButton,
            BaseCheckbox,
            ValidationProvider,
            ValidationObserver,
            BasePhoneNumberInput
        },
        props: {
            title: {
                type: String,
            },
        },
        data: () => ({
            feedback: {
                name: '',
                phone: '',
                email: '',
                companyName: '',
                region: '',
                comment: '',
                site: '',
                link: '',
                personalDataAgree: true,
            },
            phoneWithCode: '',
            formSended: false,
            langUrl: 'formRegistrationOrganizer'
        }),
        computed: {
            ...mapState('settings', ['lang']),
            ...mapState('translates', {
                translates: state => state.entities
            }),
        },
        methods: {
            async send() {
                const valid = await this.$refs.validator.validate()
                if(valid) {
                    this.feedback.phone = this.phoneWithCode;
                    this.feedback.link = document.location.href;
                    this.feedback.comment = `${this.translates[this.langUrl].feedback_part_1[this.lang]} "${ this.feedback.companyName }" ${this.translates[this.langUrl].feedback_part_3[this.lang]} ${ this.feedback.region }, ${this.translates[this.langUrl].feedback_part_2[this.lang]} ${ this.feedback.site } `
                    await store.commit('feedbacks/SET_ENTITY', this.feedback);
                    await store.dispatch('feedbacks/save');
                    await store.commit('feedbacks/CLEAR_ENTITY');
                    await this.clear()
                    await this.formReverse()
                }
                
            },
            async formReverse() {
                this.formSended = !this.formSended
            },
            async clear () {
                this.feedback = {
                    name: '',
                    phone: '',
                    email: '',
                    comment: '',
                    link: '',
                    personalDataAgree: true
                }
            },
            number(event) {
                this.phoneWithCode = event.formattedNumber
            }
        }
    }

</script>
<style lang="scss">
.form-connection {
    font-family: Inter;
    font-style: normal;
    width: 450px;
    // height: 569px;
    padding: 35px 32px ;
    background: #fff;
    box-shadow: 0px 4px 3px rgba(44, 47, 56, 0.01), 0px 4px 20px rgba(44, 47, 56, 0.08);
    border-radius: 12px;
    @media all and (max-width: 768px) {
        width: 100%;
        height: auto;
        padding: 20px 20px 25px;
    }
    &__title {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 28px;
        line-height: 140%;
        color: #273155;
        margin-bottom: 32px;
        @media all and (max-width: 768px) {
            font-size: 24px;
        }
    }
    &__personal-info {
        margin-top: 10px;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
        &__text {
        font-weight: normal;
        font-size: 14px;
        line-height: 166%;
        color: #A9AEB2;
        text-align: left;
        @media all and (max-width: 768px) {
            font-weight: 300;
            font-size: 12px;
        }
            &__accent {
                font-weight: 500;
                color: black;
            }
        }
    }
    &__sended {
        margin-top: 40%;
        &__text {
            text-align: center;
            margin-bottom: 15px;
        }
    }
}
</style>