var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.translates && _vm.translates[_vm.langUrl])?_c('div',{staticClass:"form-connection"},[_c('div',{staticClass:"form-connection__title"},[_vm._v(_vm._s(_vm.title))]),(!_vm.formSended)?_c('div',[_c('ValidationObserver',{ref:"validator",attrs:{"slim":""}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BaseTextField',{staticClass:"form",attrs:{"placeholder":_vm.translates[_vm.langUrl].placeholder_firstname[_vm.lang],"error-messages":errors[0]},model:{value:(_vm.feedback.name),callback:function ($$v) {_vm.$set(_vm.feedback, "name", $$v)},expression:"feedback.name"}})]}}],null,false,4012021534)}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BasePhoneNumberInput',{attrs:{"error-messages":errors[0]},on:{"update":function($event){return _vm.number($event)}},model:{value:(_vm.feedback.phone),callback:function ($$v) {_vm.$set(_vm.feedback, "phone", $$v)},expression:"feedback.phone"}})]}}],null,false,52677270)}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BaseTextField',{staticClass:"form",attrs:{"placeholder":_vm.translates[_vm.langUrl].placeholder_email[_vm.lang],"error-messages":errors[0]},model:{value:(_vm.feedback.email),callback:function ($$v) {_vm.$set(_vm.feedback, "email", $$v)},expression:"feedback.email"}})]}}],null,false,1480734820)}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BaseTextField',{staticClass:"form",attrs:{"placeholder":_vm.translates[_vm.langUrl].placeholder_companyName[_vm.lang],"error-messages":errors[0]},model:{value:(_vm.feedback.companyName),callback:function ($$v) {_vm.$set(_vm.feedback, "companyName", $$v)},expression:"feedback.companyName"}})]}}],null,false,3150222372)}),_c('BaseTextField',{staticClass:"form",attrs:{"placeholder":_vm.translates[_vm.langUrl].placeholder_site[_vm.lang]},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.send.apply(null, arguments)}},model:{value:(_vm.feedback.site),callback:function ($$v) {_vm.$set(_vm.feedback, "site", $$v)},expression:"feedback.site"}}),_c('BaseTextField',{staticClass:"form",attrs:{"placeholder":_vm.translates[_vm.langUrl].placeholder_region[_vm.lang]},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.send.apply(null, arguments)}},model:{value:(_vm.feedback.region),callback:function ($$v) {_vm.$set(_vm.feedback, "region", $$v)},expression:"feedback.region"}})],1),_c('BaseButton',{staticClass:"button-fill",staticStyle:{"width":"100%","margin-top":"12px"},on:{"click":_vm.send}},[_vm._v(_vm._s(_vm.translates[_vm.langUrl].button_send[_vm.lang]))])],1):_vm._e(),(_vm.formSended)?_c('div',{staticClass:"form-connection__sended"},[_c('div',{staticClass:"form-connection__sended__text",domProps:{"innerHTML":_vm._s(_vm.translates[_vm.langUrl].sended_text[_vm.lang])}}),_c('BaseButton',{staticClass:"button-fill",staticStyle:{"width":"100%"},on:{"click":_vm.formReverse}},[_vm._v(_vm._s(_vm.translates[_vm.langUrl].formReverse[_vm.lang]))])],1):_vm._e()]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }